<template>
  <div>
    <div class="navbar" @click="$router.push('/bankCount')">
      <van-icon name="down" color="rgba(0, 0, 0, 0.85)" size="20" />
      <span class="bankName">{{ $t("editBank.title") }}</span>
    </div>
    <div>
      <van-cell-group @click="bankClick">
        <van-cell :title="$t('editBank.bankName')" is-link :value="form.bankName" />
      </van-cell-group>
      <van-cell-group>
        <van-cell :title="$t('editBank.bankNo')"  :value="form.bankCardNo | formatStr" />
      </van-cell-group>
    </div>
    <div @click="saveClick" style="margin-top: 32px;padding:0 24px">
      <van-button round block color="#4ed686" plain type="default"
        >{{ $t('editBank.save') }}</van-button
      >
    </div>
  </div>
</template>

<script>
import { updateBankNo } from "@/network/login";
export default {
  name: "editBank",
  filters: {
    formatStr (str) {
      let string = str.replace(/(\w)/g, function (a, b, c, d) {
            return c <= (str.length - 5) ? '*' : a
      });
      return string
    }
  },
  data() {
    return {
      form: {
        bankName: '',
        bankCardNo: "",
        bankId: null,
      },
      bankFormatAccount: "",
    };
  },
  created() {
    let bankData = JSON.parse(window.localStorage.getItem("bankData")) ? JSON.parse(window.localStorage.getItem("bankData")) : {};
    this.bankFormatAccount = bankData.bankFormatAccount;
    this.form.bankName = this.$route.query.bankName
      ? this.$route.query.bankName
      : bankData.bankName;
    this.form.bankCardNo = bankData.bankNo;
    this.form.bankId = this.$route.query.id
      ? Number(this.$route.query.id)
      : Number(bankData.bankId);
  },
  methods: {
    saveClick() {
      updateBankNo(this.form)
        .then((res) => {
          if(res.code === '10011'){
            return
          }
          if (res.code === "000000") {
            this.$toast.success(this.$t('success'));
            let data = {
              bankFormatAccount: this.bankFormatAccount,
              bankName: this.form.bankName,
              bankNo: this.form.bankCardNo,
              bankId: this.form.bankId,
            };
            window.localStorage.setItem("bankData", JSON.stringify(data));
          } else {
            this.$dialog
              .alert({
                message: res.message,
                confirmButtonText:this.$t('stringCode.Done'),
              })
              .then(() => {
                // on close
              });
          }
        })
        .catch((err) => {
          this.$dialog
            .alert({
              message: err.message,
            })
            .then(() => {
              // on close
            });
        });
    },
    bankClick() {
      // Y表示是已经登录了的修改银行账户跳转的
      this.$router.push({
        path: "/bankNameList",
        query: {
          type: "Y",
        },
      });
    },
  },
};
</script>

<style scoped>
.navbar {
  padding: 0 25px 20px;
  display: flex;
  padding-top: 25px;
}
.navbar /deep/ .van-icon {
  vertical-align: middle;
  transform: rotate(90deg);
}
.bankName {
  vertical-align: middle;
  padding-left: 6px;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  color: #333333;
}
</style>
